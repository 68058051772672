.login-gradient {
  background: #4680ff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.no-scrollbar {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}


input[type=number] {
  -moz-appearance: textfield;
}

#City {
  background-color: yellow !important;
}

.font8 {
  font-size: 8px;
}

.cursor-pointer {
  cursor: pointer !important;
}

#dropdown {
  max-height: 30vh;
  overflow: auto;
}

#dropdown .dropdown-active {
  background-color: #d3d3d387;
  border-radius: 6px;
}

#dropdown .dropdown-list:hover {
  background-color: #d3d3d387;
  border-radius: 6px;
}

.hover-effect:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.sticky {
  position: sticky;
  position: -webkit-sticky;

  width: 100%;
  background-color: #FFF;
  z-index: 9;
  left: 0;
  right: 0;
  top: 79px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.ctooltip {
  display: none;
}

.vr{
  width: 4px;
  
}
.ctooltip-hover:hover > .ctooltip {
  display: block;
}

.borderless {
  border: 0px;
}

.borderless:focus {
  box-shadow: 0 0 0 0 rgba(13, 110, 253, .25) !important;
}

.borderless:focus-visible {
  outline: none;
}

/****************** Mobile Devices ************/
@media (max-width:576px) {
  .d-none-sm {
    display: none !important;
  }
}

/* /****************************************** */

.zoom-5 {
  zoom: 0.5;
}

.zoom-4 {
  zoom: 0.75;
}

@media print {
  .export-appointments {
    zoom: 0.6 !important; 
    transform: scale(0.95);
  }

  .export-expenses{
    zoom: 0.9 !important; 
    transform: scale(0.95);
  }

  .zoom-4 {
    zoom: 1;
  }

  .zoom-5 {
    zoom: 1;
  }
  
  .receipt {
    transform: scale(.9);
    /* height: 45vh !important; */
  }

  .admitCard {
    height: 25vh;
    margin-top: 0 !important;
  }

  .pagebreak {
    page-break-before: always;
    page-break-inside: avoid;
  }

  p {
    color: #000 ;
    /* font-weight: 600; */
  }
 
  .print-card{
    border: none !important;

    height: 100vh !important;
  }

  .medicine-invoice {
    transform: scale(.95);
  }

  .medicine-invoice p{
    font-weight: 500 !important;
  }
  
}

#textSlider div {
  color: #292828;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}

.iamCol {
  text-align: right;
  float: left;
  font-weight: 300;
  font-size: 30pt;
}

.iamCol p:nth-child(2) {
  margin-top: -20pt !important;
}

.slideCol {
  text-align: left;
  overflow: hidden;
  font-weight: 900;
  font-size: 70pt;
  display: block;
  white-space: nowrap;
}

.scroller {
  height: 50pt;
  line-height: 50pt;
  overflow: hidden;
}

.scroller h1 {
  line-height: 50pt;
}

.scroller .inner {
  animation: 10s normal infinite running scroll;
}

.notification {
  position: absolute;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 16px;
  /* padding: 12px; */
  width: 20rem;
  height: 27rem;
  top: 85%;
  right: 0;
  overflow-y: auto;
}

.notification .item {
  padding: 12px;
}

.notification .options {
  display: none;
}

.notification .item:hover {
  background-color: aliceblue;

  .icon {
    background-color: #4680ff;
    color: #FFF;
  }

  .options{
    display: flex;
    justify-content: end;
    align-items: center;

    margin-top: 15px;
  }
}

.multi-select {
  width: 100%;
  position: relative;
  background-color: #fff;
  
  .value {
    padding: 12px 12px;
  } 
}

.multi-select:hover {
  background-color: transparent;
}

.multi-select-sm {
  width: 100%;
  position: relative;
  background-color: #fff;

  .value {
    padding: 5px 10px;
  } 
}
.multi-select-sm:hover {
  background-color: transparent;
}

.multi-select .value {
  /* padding: 5px 10px; */
  border: 1px solid #bec8d0;
}

.multi-select .dropdown {
  position: absolute;
  background: white;
  width: 100%;
  z-index: 999;
  max-height: 15rem;
  overflow: auto;
}

.multi-select li {
  padding: 6px 12px;
}

.multi-select li:hover {
  background-color: #ededed;
  border-radius: 6px;
}

.multi-select .active {
  background-color: #ededed;
  border-radius: 6px;
}

.bg-secondary2 {
  background-color: rgb(217 217 217 / 40%);
}

@keyframes scroll {
  0% {
    margin-top: 0px;
  }

  25% {
    margin-top: 0px;
  }

  35% {
    margin-top: -50pt;
  }

  /* 40% {
    margin-top: -70pt;
  }  */

  60% {
    margin-top: -50pt;
  } 

  /* 65% {
    margin-top: -140pt;
  }

  75% {
    margin-top: -210pt;
  }

  90% {
    margin-top: -210pt;
  } */

  100% {
    margin-top: 0px;
  }
}

.shadow-new{
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1)
}

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) and (max-width : 479px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .iamCol {
    text-align: center;
    font-size: 20pt;
  }

  .iamCol p {
    display: inline !important;
  }

  .slideCol {
    font-size: 25pt;
    text-align: center;
    margin-top: -20px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) and (max-width : 765px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .iamCol {
    text-align: center;
    font-size: 25pt;
  }

  .iamCol p {
    display: inline !important;
  }

  .slideCol {
    font-size: 38pt;
    text-align: center;
    margin-top: -20px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) and (max-width : 992px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .iamCol {
    text-align: center;
    font-size: 30pt;
  }

  .iamCol p {
    display: inline !important;
  }

  .slideCol {
    font-size: 50pt;
    text-align: center;
    margin-top: 5px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) and (max-width : 1200px) {
  #textSlider.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .iamCol {
    text-align: right;
    font-size: 30pt;
  }

  .slideCol {
    font-size: 55pt;
    text-align: left;
  }
}